import React from "react"
import stripHtml from "string-strip-html"
import PageBuilder from "../components/PageBuilder"
import SEO from "../components/seo"

function escape_attribute(s, preserveCR = false) {
  preserveCR = preserveCR ? "&#13;" : "\n"
  return (
    ("" + s) /* Forces the conversion to string. */
      .replace(/&/g, "&amp;") /* This MUST be the 1st replacement. */
      .replace(/'/g, "&apos;") /* The 4 other predefined entities, required. */
      .replace(/"/g, "&quot;")
      .replace(/</g, "&lt;")
      .replace(/>/g, "&gt;")
      /*
    You may add other replacements here for HTML only 
    (but it's not necessary).
    Or for XML, only if the named entities are defined in its DTD.
    */
      .replace(/\r\n/g, preserveCR) /* Must be before the next replacement. */
      .replace(/[\r\n]/g, preserveCR)
  )
}
function find_first_comp_type(type, comps) {
  let comp = comps.find(c => c.type === type)
  if (!comp) {
    comp = comps.map(c => c.children && find_first_comp_type(type, c.children))
    comp = comp && comp.find(c => c && c.type === type)
  }
  return comp
}

function meta_desc({ post }) {
  let desc = ""
  if (post.meta_desc && post.meta_desc[0] && post.meta_desc[0].length > 0) {
    desc = post.meta_desc
  } else {
    const content = find_first_comp_type("content", post.lemonade_content)
    if (content) {
      desc = stripHtml(content.attributes.content)
    }
  }
  desc = escape_attribute(desc)
  if (desc.length > 155) {
    desc = desc.slice(0, 152) + "..."
  }
  return desc
}

export default function Page({ pageContext }) {
  return (
    <>
      <SEO
        title={pageContext.post.title.rendered}
        description={meta_desc(pageContext)}
      />
      <PageBuilder {...pageContext} />
    </>
  )
}
